// Place for temp fixes

.form-floating > .form-select {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}

// temp fix for scrollbar styling (wip)
@import "scrollbar";

// temp fix .chartjs-tooltip-body-item padding
@import "charts";

// temp fix for dark-theme select options
.dark-theme select > option {
  background-color: var(--cui-body-bg, #282933);
}

// temp fix for dark-theme .picker.show .input-group
.dark-theme .picker.show .input-group {
  background-color: var(--cui-input-bg, #282933);
}

// temp fix for dark-theme .form-control:disabled
.dark-theme .form-control:disabled {
  background-color: var(--cui-input-bg, #282933);
}

// temp fix for .form-floating .form-select
html:not([dir=rtl]) .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
