// Here you can add other styles

.color-version {
    p {
        color: $color-version;
    }
}

.bg-login-img {
    background: url('../assets/img/bg/bg_01_new.jpg') no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
}

.maxWidthSelect {
    max-width: 4rem;
    max-height: 2rem;
}

.icon-margin-right {
    margin-right: 0.50rem;
}